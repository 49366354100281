<template>
    <div>
        <div class="messages">
            <div class="clearfix messages-search table-tools">
                <!-- <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="cloud-sync" @click="toSync" :loading="syncLoading" type="dashed">同步朋友圈</a-button>
                        </a-form-item>
                    </a-form>
                </div> -->
                <div class="search">
                    <a-form layout='inline' @submit="searchMsg">
                        <a-form-item>
                            <a-select style="width: 200px" v-model='searchParams.search.creator' allowClear showSearch @change="handleUserChange" placeholder="请选择员工" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['userid']">{{ d['name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-range-picker
                            :ranges="rangesData"
                            v-model='searchDatas.add'
                            :disabled-date="disabledDate"
                            allowClear
                            style="width: 240px"
                            @change="(val,time)=>handleTime(val,time,'add')" />
                        </a-form-item>
                        <a-form-item>
                            <!-- <a-button @click="searchMsg" html-type="submit" type="dashed" >搜索</a-button> -->
                            <a-button type="primary" :loading="loading" html-type="submit" @click="searchMsg">搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div 
                class="messages-list"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading && list.length==0" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <a-card class="messages-card">
                            <div class="messages-card-nav">
                                <a-avatar :src="item.creator?item.creator.avatar:''" :size="35" icon="user" />
                                <div class="messages-card-name">
                                    <div>{{item.creator?item.creator.name:''}}</div>
                                    <div style="font-size: 12px;">{{item.msgtime}}</div>
                                </div>
                            </div>
                            <div class="messages-card-mar messages-card-text">
                                {{item.text?item.text.content:''}}
                            </div>
                            <div class="messages-card-mar messages-card-img">
                                <img :preview="index" v-for="(it,ind) in item.image" :key="ind" :src="it.pic_url">
                                <div v-if="item.video" @click="showVideoModal(item.video.video_url)"  class="messages-card-video">
                                    <!-- <video style="background:#000;border-radius:2px" :src="item.video.video_url"  width="60" height="60">
                                        Your browser does not support the video tag.
                                    </video> -->
                                    <img style="width:60px;height:60px;border-radius:2px" :src="item.video.video_thumb_url" alt="">
                                    <div class="messages-card-video-icon">
                                        <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                                    </div>
                                </div>
                            </div>
                            <div class="messages-card-mar messages-card-location" v-if="item.link">
                                <span>
                                    <a  @click.stop v-clipboard:copy="item.link.url" v-clipboard:success="onCopy" v-clipboard:error="onError">{{item.link.title}}<a-icon type="copy" :style="{ fontSize: '12px'}" /></a>
                                </span>
                            </div>
                            <div class="messages-card-mar messages-card-location">
                                <span>{{item.location?item.location.name:''}}</span>
                            </div>
                            <template slot="actions" class="ant-card-actions">
                                <div class="messages-can-see">
                                    <a-popover v-model="item.user_list_show" title="" trigger="click" placement="right">
                                        <userContent slot="content" :item="item" />
                                        <div>
                                            <a-tooltip>
                                                <template slot="title">
                                                朋友圈可见的客户
                                                </template>
                                                <a-icon key="user" type="user" /><span style="margin-left:5px">{{item.user_count}}</span>
                                            </a-tooltip>
                                        </div>
                                    </a-popover>
                                </div>
                                <div class="messages-can-see">
                                    <a-popover v-model="item.comment_list_show" title="" trigger="click" placement="right">
                                        <commentsContent slot="content" :item="item" />
                                        <div>
                                            <a-tooltip>
                                                <template slot="title">
                                                朋友圈回复统计
                                                </template>
                                                <a-icon key="message" type="message" /><span style="margin-left:5px">{{item.comment_count}}</span>
                                            </a-tooltip>
                                        </div>
                                    </a-popover>
                                </div>
                                <div class="messages-can-see">
                                    <a-popover v-model="item.like_list_show" title="" trigger="click" placement="right">
                                        <likesContent slot="content" :item="item" />
                                        <div>
                                            <a-tooltip>
                                                <template slot="title">
                                                朋友圈点赞统计
                                                </template>
                                                <a-icon key="like" type="like" /><span style="margin-left:5px">{{item.like_count}}</span>
                                            </a-tooltip>
                                        </div>
                                    </a-popover>
                                </div>
                            </template>
                        </a-card>
                    </a-list-item>
                    <div slot="footer">
                        <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                        <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                    </div>
                </a-list>
            </div>
        </div>
        <videoModal v-if="isVideoModal" :videoSrc='video'/>
    </div>
</template>

<script>
    import videoModal from '@/views/orginization/messages/videoModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import ranges from "@/common/mixins/ranges"
    import userContent from "./userContent"
    import likesContent from "./likesContent"
    import commentsContent from "./commentsContent"
    export default {
        name:"friend",
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                busy: true,
                visible: false,
                loading: false,
                isVideoModal: false,
                syncLoading: false,
                list: [],
                studios: [],
                searchDatas:{},
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
            }
        },
        mixins:[ranges],
        directives: { infiniteScroll },
        components: {
            commentsContent,
            likesContent,
            userContent,
            videoModal,
        },
        created () {
            this.getList()
            this.getStaff()
        },
        methods: {
            onCopy(){
                this.$store.dispatch('toolsClickAction', {data: {event_type:'event_qywx_msg_friend_copy_click'}})
                this.$message.success('已复制到剪切板!')
            },
            onError(){
                this.$message.error('操作失败!')
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].text.indexOf(input) >= 0;
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('missionMomentAction',{data:this.searchParams})
                if(this.searchParams.page == 1){
                    this.busy = false
                }
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                }
                if(res._meta.totalCount == this.list.length){
                    this.busy = true
                }
                this.pageParams = res._meta
                this.loading = false
            },
            async getStaff(val){
                let obj = {name:val}
                let res = await this.$store.dispatch('wxworkStaffIndexAction', {data:obj})
                this.studios = res.items
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            showVideoModal(src){
                this.video = src
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
                this.searchMsg()
            },
            handleUserChange(){
                this.searchMsg()
            },
            handleSearch(val){
                this.getStaff(val)
            },
            searchMsg(e){
                e?e.preventDefault():''
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                this.getList()
            },
            async toSync(){
                this.syncLoading = true
                await this.$store.dispatch('missionSyncMomentAction',{})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.syncLoading = false
                })
                .catch(err=>{
                    this.syncLoading = false
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .messages{
        &-list{
            height: calc(100vh - 205px);
            overflow-y: auto;
        }
        &-search{
            width: 700px;
            margin: 0 auto;
        }
        &-send{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e8e8e8;
            textarea{
                resize: none;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
            }
            &-iconL{
                display: flex;
                align-items: center;
                .ant-btn{
                    border: none;
                    box-shadow:none;
                    padding: 0px;
                }
            }
            &-upload{
                margin-right: 15px;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                }
            }
            &-imgBox{
                margin-right: 5px;
                margin-bottom: 5px;
                position: relative;
            }
            &-audio{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                &-icon{
                    margin-left: 10px;
                    font-size: 24px;
                    cursor: pointer;
                }
                &-loading{
                    width: 300px;
                    height: 54px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
                margin-right: 5px;
                margin-bottom: 5px;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-close{
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250,250,250,.5);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
            }
            &-loading{
                width: 60px;
                height: 60px;
            }

        }
        &-card{
            width: 700px;
            margin: 0 auto;
            &-nav{
                display: flex;
            }
            &-name{
                margin-left: 20px;
            }
            &-mar{
                margin-left: 55px;
                margin-top: 10px;
            }
            &-text{
                white-space: normal;
                word-break: break-all;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-rate{
                display: flex;
                align-items: center;
            }
            &-rateImg{
                width: 100px;
                margin-right: 50px;
            }
            &-rateBox{
                display: flex;
                align-items: center;
            }
            &-rateName{
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis
            }
            &-location{
                font-size: 12px;
                color: #bfbfbf;
            }
        }
        &-comments{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 12px 24px;
            textarea{
                resize: none;
                border:1px solid #fff;
            }
            &-reply{
                border: 1px solid #e8e8e8;
                padding: 5px;
                border-radius: 2px;
                margin-bottom: 5px;
            }
            &-btn{
                text-align: right;
                margin-top: 5px;
            }
        }
        &-can-see{
            display: inline-block;
            cursor: pointer;
        }
        .ant-list-item{
            border-bottom: none;
            display: block;
        }
    }
</style>
<style lang="scss">
    .messages{
        .ant-comment-inner{
            padding-top: 5px;
            padding-bottom: 0px;
            p{
                margin-bottom:0px;
                padding-bottom: 5px;
            }
        }
        .messages-comments-border{
            p{
                border-bottom: 1px solid #e8e8e8;
            }
        }
        .ant-comment-actions{
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
</style>