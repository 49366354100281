<template>
    <div>
        <div 
            class="messages-can-see-content" 
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="userBusy"
            :infinite-scroll-distance="10">
            <vue-element-loading :active="userLoading && canSeeList.length==0" color="#00cca2" spinner="spinner"/>
            <a-list item-layout="horizontal" :data-source="canSeeList">
                <a-list-item slot="renderItem" slot-scope="it, index">
                <div class="messages-can-see-user">
                    <span style="margin-right:5px">{{index+1}}</span>
                    <a-avatar :src="it.avatar"/>
                    <span class="messages-can-see-user-name">{{it.remark}} {{`(${it.name})`}}</span>
                </div>
                </a-list-item>
                <div slot="footer">
                    <vue-element-loading :active="userLoading && canSeeList.length!=0" color="#00cca2" spinner="spinner"/>
                    <div style="text-align:center" v-if="userBusy && canSeeList.length!=0">没有更多了~</div>
                </div>
            </a-list>
        </div>
    </div>
</template>

<script>
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'userContent',
        data() {
            return {
                userBusy:true,
                userLoading:false,
                canSeeList:[],
                pageUserParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchUserParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
            }
        },
        props:{
            item:Object
        },
        directives: { infiniteScroll },
        created () {
            this.searchUserParams.search.moment_id = this.item.moment_id
            this.searchUserParams.search.userid = this.item.creator.userid
            this.canSeeList = []
            this.searchUserParams.page = 1
            this.getUser()
        },
        methods: {
            async getUser() {
                this.userLoading = true
                let res = await this.$store.dispatch('missionMomentUserListAction',{data:this.searchUserParams})
                if(this.searchUserParams.page == 1){
                    this.userBusy = false
                }
                if(res.items.length >0){
                    this.canSeeList = [...this.canSeeList,...res.items]
                }
                if(res._meta.totalCount == this.canSeeList.length){
                    this.userBusy = true
                }
                this.pageUserParams = res._meta
                this.userLoading = false
            },
            handleInfiniteOnLoad(){
                if(!this.userBusy){
                    this.searchUserParams.page++
                    this.getUser()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .messages-can-see-content{
        padding: 10px;
        width: 350px;
        height: 250px;
        overflow-y: auto;
    }
    .messages-can-see-user{
        display: flex;
        align-items: center;
        &-name{
            margin-left:15px;
            display: inline-block;
            width: 280px;
        }
    }
</style>