<template>
    <div @click.stop class="phoneModal-mobile">
        <div class="phonemodal-phone">
            <img style="width:324px" src="@/assets/phone.png">
            <div class="phonemodal-phone-content">
                <div class="phonemodal-phone-item">
                    <a-avatar v-if="creator_info" shape="square" :size="26" icon="user" :src="creator_info.avatar" />
                    <a-avatar v-else shape="square" :size="26" icon="user" />
                    <div>
                        <div v-if="creator_info" class="phonemodal-phone-item-name">{{creator_info.name}}</div>
                        <div class="phonemodal-phone-item-text">
                            {{msg_data.content}}
                        </div>
                    </div>
                </div>
                <div v-for="(item,index) in attachments" :key="index" class="phonemodal-phone-item">
                    <a-avatar v-if="creator_info" shape="square" :size="26" icon="user" :src="creator_info.avatar" />
                    <a-avatar v-else shape="square" :size="26" icon="user" />
                    <div v-if="item.msgtype == 'image' || item.msgtype == 'video'">
                        <div v-if="creator_info" class="phonemodal-phone-item-name">{{creator_info.name}}</div>
                        <div v-if="item.msgtype == 'image'" class="phonemodal-phone-item-img">
                            <img v-show="!loading" :src="item.image.pic_url" @load="handleOnload" >
                            <div v-if="loading" style="width:100px;height:100px">
                                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                            </div>
                        </div>
                        <div v-if="item.msgtype == 'video'" class="phonemodal-phone-item-img">
                            <div id="dplayer"></div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="creator_info" class="phonemodal-phone-item-name">{{creator_info.name}}</div>
                        <div class="phonemodal-phone-item-link">
                            <div class="phonemodal-phone-item-linkTitle">{{item.link.title}}</div>
                            <div class="phonemodal-phone-item-linkWarp">
                                <div class="phonemodal-phone-item-linkDesc">{{item.link.desc}}</div>
                                <img class="phonemodal-phone-item-linkImg" :src="item.link.picurl">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DPlayer from 'dplayer';
    export default {
        name:'phoneModal',
        inject: ['parent'],
        props: {
            item: Object
        },
        data() {
            return {
                loading:true,
                msg_data:{},
                creator_info:{},
                attachments:[],
            }
        },
        created () {
            this.msg_data = this.item.text
            this.creator_info = this.item.creator_info
            this.attachments = this.item.attachments
            this.attachments.map((item)=>{
                if(item.msgtype=='video'){
                    this.$nextTick(() => {
                        let playerOtions =  {
                            container: document.getElementById('dplayer'),
                            autoplay:true,
                            live: false,
                            muted: true,
                            screenshot:true,
                            playbackSpeed:false,
                            preload: 'auto',
                            contextmenu:[],
                            video: {
                                url: item.video.video_url,
                            }
                        }
                        new DPlayer(playerOtions)
                    })
                }
            })
        },
        methods: {
            handleOnload() {
                // console.log('dlldldldl')
                this.loading = false
            }
        },
    }
</script>

<style lang="scss">
    .phoneModal-mobile{
        position: fixed!important;
        left: 50%;
        top: 60px;
        margin-left: -175px;
        z-index: 4100;
        border-radius: 118px;
    }
    .phonemodal{
        &-phone{
            display: inline-block;
            position: relative;
            &-content{
                width: 275px;
                height: 410px;
                position: absolute;
                top: 110px;
                left: 24px;
                overflow-y: scroll;
                padding: 10px 5px 0 5px;
            }
            &-item{
                display: flex;
                margin-bottom: 10px;
                &-name{
                    margin-left: 10px;
                    margin-bottom: 5px;
                    font-size: 12px;
                }
                &-text{
                    width: 200px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                    word-break: break-all;
                    white-space: pre-wrap;
                }
                &-img{
                    max-width: 200px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                    img{
                        max-width: 184px;
                    }
                }
                &-link{
                    width: 200px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                }
                &-linkWarp{
                    display: flex;
                }
                &-linkTitle{
                    width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
                &-linkDesc{
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-flex: 1;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 35px;
                    font-size: 12px;
                    color: rgba(0,0,0,.64);
                }
                &-linkImg{
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
</style>