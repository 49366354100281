<template>
    <div>
        <div class="clearfix table-tools">
            <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-button @click='showEditModal' type="dashed" icon="plus" >新增</a-button>
                        <a-button icon="cloud-sync" @click="toSync" :loading="syncLoading" style="margin-left:10px" type="dashed">同步企微</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div class="search">
                <a-form layout='inline' @submit="searchList">
                    <!-- <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>群发名称</span>
                        </template>
                        <a-input allowClear v-model.trim="searchParams.search.chat_name" placeholder="请输入群发名称" style="width: 160px"/>
                        </a-tooltip>
                    </a-form-item> -->
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <a-select v-model='searchParams.search.create_type' placeholder="请选择类型" style="width: 160px">
                            <a-select-option :value="0">企业群发</a-select-option>
                            <a-select-option :value="1">个人群发</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <!-- <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button> -->
                        <a-button type="primary" :loading="loading" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div @click.stop class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' rowKey="msgid"
                :selection="{key: 'key'}" :scroll="{ x: 1500 }"
                :columns="columns" :dataSource="list">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="create_type" slot-scope="text">
                    <span>{{text == '1'? '个人群发':'企业群发'}}</span>
                </template>
                <template slot="success_count" slot-scope="text,record">
                    <a-popover v-model="record.success_list_show" title="" trigger="click" placement="right">
                        <commentsContent slot="content" :item="record" status="ok" />
                        <div style="cursor: pointer">
                            <a href="javascript:;">
                                {{text}}
                            </a>
                        </div>
                    </a-popover>
                </template>
                <template slot="creator_info" slot-scope="creator_info">
                    <span v-if="creator_info">{{creator_info.name}}</span>
                </template>
                <template slot="text" slot-scope="text, record">
                    <a @click="showDetailModal(record)" href="javascript:;">【共{{record.attachments.length + 1}}条】</a>
                </template>
            </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
        <phoneModal v-if="detail_visible" :item="modalData"/>
        <div @click="handleDetailOk" v-if="detail_visible" class="message-bg"></div>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
//   {title: '群发名称', dataIndex: 'chat_name',key: 'chat_name' },
  {title: '群发类型', dataIndex: 'create_type',key: 'create_type',scopedSlots: { customRender: 'create_type' } },
  {title: '群发账号', dataIndex: 'creator_info',key: 'creator_info',scopedSlots: { customRender: 'creator_info' } },
  {title: '群发内容', dataIndex: 'text',key: 'text' ,scopedSlots: { customRender: 'text' },width:"120px"},
  
//   {title: '已发送群主', dataIndex: 'sended_staff_count',key: '4' ,align:'right',width:"120px"},
//   {title: '未发送群主', dataIndex: 'unsended_staff_count',key: '5' ,align:'right',width:"120px"},
  {title: '送达群聊', dataIndex: 'success_count',key: '6' ,align:'right',width:"120px",scopedSlots: { customRender: 'success_count' }},
  {title: '发送时间', dataIndex: 'create_time',key: 'create_time',align:'right' },
//   {title: '未送达群聊', dataIndex: 'unsuccess_count',key: '7' ,align:'right',width:"120px"},
//   { title: '操作', key: 'operation',fixed:'right', width:60,align:"center",scopedSlots: { customRender: 'action' }}
]
    import phoneModal from '../phoneModal'
    import commentsContent from "./commentsContent"
    import tableMixins from '@/common/mixins/table'
    export default {
        name:"group",
        data() {
            return {
                columns,
                loading: false,
                syncLoading: false,
                detail_visible: false,
                list: [],
            }
        },
        components: {
            phoneModal,
            commentsContent
        },
        mixins: [ tableMixins ],
        methods: {
            async getList(){
                this.loading = true
                await this.$store.dispatch('missionGroupAction',{data: this.searchParams})
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            showDetailModal(item){
                this.modalData = item
                this.detail_visible = true
            },
            handleDetailOk(e) {
                this.detail_visible = false
            },
            showEditModal() {
                this.$router.push({path:'/crm/sendMessage/group/edit'})
            },
            async toSync(){
                this.syncLoading = true
                await this.$store.dispatch('missionSyncGroupAction',{})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.syncLoading = false
                })
                .catch(err=>{
                    this.syncLoading = false
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .message-bg{
        position: fixed;
        z-index: 4000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
    }
</style>